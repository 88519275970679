import React from 'react'
import system from 'system-components/emotion'
import styled from 'react-emotion'

const SliderContainer = styled('div')`
  flex-grow: 1;
  height: 0;
`

const Slider = styled('div')`
  height: 100%;
  display: flex;
  position: relative;
  transition: left 0.2s ease-in-out;
  left: ${props => props.left || 0}%;
`

const Slide = styled(system({
  width: 1
}))`
  flex: 1 0 ${props => props.width || 100}%;
`

export default ({ children, viewCount=1, position=0 }) => {
  const width = 100 / viewCount
  return <SliderContainer>
    <Slider left={position * -width}>
      {children.map((child, i) => {
        return <Slide width={width} key={i} >{child}</Slide>
      })}
    </Slider>
  </SliderContainer>
}
