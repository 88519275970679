import React, { Component } from 'react'
import styled from 'react-emotion'
import system from 'system-components/emotion'
import copyToClipboard from 'clipboard-copy'
import { Flex, Box, Button } from 'components'

const TruncatedTextBox = styled(Box)`
  white-space: nowrap;
  overflow-x: scroll;
  border: none;
`

const Wrap = system({
  is: Flex
},
'borderRadius')

class CopyText extends Component {
  constructor (props) {
    super(props)
    this.handleCopyToClipboard = this.handleCopyToClipboard.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.ref = React.createRef()
    this.state = {
      copied: false
    }
  }
  handleSelect () {
    this.ref.current.select()
  }
  handleCopyToClipboard (e) {
    const { text } = this.props
    copyToClipboard(text)
    this.ref.current.select()
    this.setState({
      copied: true
    })
    setTimeout(() => {
      this.setState({
        copied: false
      })
    }, 1400)
  }
  render () {
    const {
      text,
      buttonText='Copy',
      buttonSuccessText='Copied to Clipboard',
      ...rest
    } = this.props
    const { copied } = this.state
    return <Wrap {...rest} borderRadius={2} css="overflow: hidden;">
      <TruncatedTextBox is="input" flex="1" border="none" value={text} readOnly
        bg="white" color="primary" px={3} py={2} width="100%"
        onClick={this.handleSelect} innerRef={this.ref} />
      <Button onClick={this.handleCopyToClipboard} px={4} bg="blacks.4" borderRadius="none">
        {copied ? buttonSuccessText : buttonText}
      </Button>
    </Wrap>
  }
}

export default CopyText
