import React from 'react'
import system from 'system-components/emotion'
import styled from 'react-emotion'

const OverlayWindow = system({
  maxWidth: 5,
  width: '100%'
})

const OverlayContainer = styled('div')`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  pointer-events: none;
`

const OverlayPane = styled(system({
  bg: 'blacks.4'
}))`
  pointer-events: auto;
  flex: 1;
  z-index: 10;
  backdrop-filter: blur(2px);
`

const Overlay = () => (
  <OverlayContainer>
    <OverlayPane />
    <OverlayWindow />
    <OverlayPane />
  </OverlayContainer>
)

export default Overlay
