import React from 'react'
import range from 'ramda/src/range'
import { Button, Box } from 'components'

export const defaultIconList = [
  '😢',
  '🙁',
  '🙂',
  '😀'
]

// export const defaultIconList = [
//   <img width="32px" src={require('../images/very-sad.svg')} alt="😢" />,
//   <img width="32px" src={require('../images/sad.svg')} alt="🙁" />,
//   <img width="32px" src={require('../images/happy.svg')} alt="🙂" />,
//   <img width="32px" src={require('../images/very-happy.svg')} alt="😀" />
// ]

const cumulativeComparison = (a, b) => a <= b
const equalComparison = (a, b) => a == b

// onmousedown and onclick to handle event propagation requirements
const Rating = ({
  count=4,
  rating=0,
  onClick=()=>{},
  cumulative,
  iconList=defaultIconList,
  icon,
  ...rest
} = {}) => {
  const ratings = range(0, count)
  const comparison = cumulative ? cumulativeComparison : equalComparison
  return <Box {...rest}>
    {ratings.map(i => (
      <Button px={1} py={1} key={i} onClick={() => onClick(i)}
        color='primary'
        css={`opacity: ${comparison(i, rating) ? 1 : 0.3};`}>
        {icon || iconList[i]}
      </Button>
    ))}
  </Box>
}

export default Rating
