import React, { Component } from 'react'
import { connect } from 'react-redux'
import system from 'system-components/emotion'
import styled from 'react-emotion'
import { Icon, Button, Slider } from 'components'

const Container = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const ButtonContainer = styled(system({
  my: 3,
  mx: 4
}))`
  display: flex;
  justify-content: space-between;
`

const Carousel = ({ children, viewCount=1, nextStep, stepIndex, options, procons }) => {
  const steps = [{
    position: 0,
    prevHidden: true,
    nextText: 'Ready to Begin?'
  }, {
    position: 1,
    prevText: 'Instructions',
    nextInactive: (options.length < 2) || !options[1].value
  }, {
    position: 2,
    prevText: 'Choices',
    nextInactive: !procons[0].value
  }, {
    position: 3,
    nextHidden: true,
    prevText: 'Pros & Cons'
  }, {
    position: 4,
    nextHidden: true,
    prevText: 'How we got here?'
  }]

  const step = steps[stepIndex] || {}
  return <Container>
    <Slider viewCount={viewCount} position={step.position}>
      {children}
    </Slider>
    <ButtonContainer>
      {!step.prevHidden
        ? <Button onClick={() => nextStep(-1)} bg="blacks.5">
            <Icon name="triangleLeft" mr={2} mb={1} css="vertical-align: middle;" />
            {step.prevText || 'Back'}
          </Button>
        : <div />}
      {!step.nextHidden
        ? <Button onClick={() => nextStep(1)} bg="blacks.6" css={`opacity: ${step.nextInactive ? '0.5' : '1'};`} disabled={step.nextInactive}>
          {step.nextText || 'Next'}
          <Icon name="triangleRight" ml={2} mb={1} css="vertical-align: middle;" />
        </Button>
        : <div />}
    </ButtonContainer>
  </Container>
}

export default connect(
  state => ({
    stepIndex: state.ui.stepIndex,
    viewCount: state.ui.viewCount,
    options: state.options,
    procons: state.procons
  }),
  dispatch => ({
    nextStep: dispatch.ui.nextStep
  })
)(Carousel)
