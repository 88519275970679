import clamp from 'ramda/src/clamp'
import { dispatch } from 'store'
import queryState from 'lib/queryState'

const stepIndex = (queryState.options && queryState.procons) ? 4 : 0

const state = {
  viewCount: 1,
  stepIndex,
  proconIndex: 0,
  optionIndex: 0
}

export default {
  state,
  reducers: {
    setState: (state, payload) => ({ ...state, ...payload }),
    setStep (state, stepIndex) {
      return {
        ...state,
        stepIndex
      }
    }
  },
  effects: {
    lastSubIndexes (_, { options, procons }) {
      this.setState({
        optionIndex: options.length - 1,
        proconIndex: procons.length - 1
      })
    },
    nextSubIndexes (amount=1, { options, procons, ratings, ui }) {
      amount = clamp(-1, 1, amount)
      const { optionIndex, proconIndex } = ui
      let nextOptionIndex = optionIndex
      let nextProconIndex = clamp(0, procons.length - 1, proconIndex + amount)
      if (nextProconIndex === proconIndex) {
        nextOptionIndex = clamp(0, options.length - 1, optionIndex + amount)
        if (nextOptionIndex !== optionIndex) {
          const adjustFrom = amount > 0 ? -1 : procons.length
          nextProconIndex = adjustFrom + amount
        }
      }

      // const optionId = options[nextOptionIndex].id
      // const proconId = procons[nextProconIndex].id
      // const rating = (ratings[optionId] || {})[proconId]

      // dispatch.ratings.edit({
      //   optionId,
      //   proconId,
      //   rating
      // })

      this.setState({
        optionIndex: nextOptionIndex,
        proconIndex: nextProconIndex
      })
    },
    nextStep (amount=1, { ui }) {
      const { viewCount, stepIndex } = ui
      const newStepIndex = clamp(0, 5 - viewCount, stepIndex + amount)
      if (newStepIndex === 3) {
        dispatch.ratings.clean()
        this.setState({
          optionIndex: 0,
          proconIndex: 0
        })
      }
      this.setStep(newStepIndex)
    }
  }
}
