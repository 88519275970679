import system from 'system-components/emotion'

const Text = system(
  {
    is: 'p',
    lineHeight: 'copy'
  },
  'space',
  'textAlign',
  'color',
  'fontWeight',
  'fontSize'
)

export default Text
