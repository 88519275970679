import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import system from 'system-components/emotion'
import styled from 'react-emotion'
import { themeGet } from 'styled-system'
import { OptionItem, ProConItem, Button, Text } from 'components'

export const ItemWrap = system({
  borderBottom: 1,
  display: 'flex',
  borderColor: 'blacks.4',
  bg: 'white'
}, 'space')

const AddButton = styled(system({
  is: Button,
  py: 4,
  px: 4,
  width: '100%',
  overflow: 'hidden',
  boxShadow: 'inset 0 20px 40px -40px #000000',
  borderRadius: 0,
  borderBottom: '1px dashed',
  borderColor: 'blacks.4',
  color: 'blacks.6'
}))`
  display: ${props => props.disabled ? 'none' : 'block'};
  :hover, :active {
    background: ${themeGet('colors.blacks.2')};
  }
`

class ItemList extends Component {
  constructor (props) {
    super(props)
    this.setRef = this.setRef.bind(this)
    this.handleRemove = this.handleRemove.bind(this)
    this.handleAdd = this.handleAdd.bind(this)
  }
  componentDidUpdate () {
    if (this.focusLast) {
      this.lastRef.current.focus()
      this.focusLast = false
    }
  }
  handleRemove (id) {
    const { remove } = this.props
    if (this.keepFocus) {
      this.keepFocus = false
      return
    }
    this.focusLast = true
    remove(id)
  }
  handleAdd (e) {
    const { add } = this.props
    e.preventDefault()
    this.focusLast = true
    add()
  }
  setRef (ref, i) {
    const { list } = this.props
    if (i === list.length - 1) {
      this.lastRef = ref
    }
  }
  render () {
    const { list, ItemInput, edit, remove } = this.props
    const lastItemBlank = list.length && list[list.length - 1].value === ''
    // const lastItemFocued = (typeof window !== 'undefined') && this.lastRef && this.lastRef.current === document.activeElement
    return <Fragment>
      {list.map((item, i) => (
        <ItemWrap key={item.id}>
          <ItemInput item={item} inputRef={ref => this.setRef(ref, i)}
            add={this.handleAdd} edit={edit} remove={this.handleRemove} onBlur={remove} />
        </ItemWrap>
      ))}
      <AddButton key="addButton" disabled={lastItemBlank} css="outline: none;"
        onMouseDown={this.handleAdd}>
        Add: <b>Click Here</b> or <b>Press Enter</b>
      </AddButton>
      <Text color="blacks.6" my={4} textAlign="center">
        <b>Pro Tips:</b><br />
        Press <b>Enter</b> while editing to <b>add</b><br />
        Press <b>Delete</b> in an empty input to <b>remove</b>
      </Text>
    </Fragment>
  }
}

export const OptionItemList = connect(
  state => ({
    list: state.options,
    ItemInput: OptionItem
  }),
  dispatch => ({
    edit: dispatch.options.edit,
    add: dispatch.options.add,
    remove: dispatch.options.remove
  })
)(ItemList)

export const ProConItemList = connect(
  state => ({
    list: state.procons,
    ItemInput: ProConItem
  }),
  dispatch => ({
    edit: dispatch.procons.edit,
    add: dispatch.procons.add,
    remove: dispatch.procons.remove
  })
)(ItemList)

export default ItemList
