import { default as uid } from 'cuid'
import adjust from 'ramda/src/adjust'
import findIndex from 'ramda/src/findIndex'
import remove from 'ramda/src/remove'
import queryState from 'lib/queryState'

const defaultState = [{
  id: 'a',
  value: '',
  weight: 2
}]

// const defaultState = [{
//   id: 'a',
//   value: 'Feeling of accomplishment.',
//   weight: 2
// }, {
//   id: 'b',
//   value: 'Sweet sweet speed!',
//   weight: 2
// }, {
//   id: 'c',
//   value: 'Debt free children.',
//   weight: 3
// }]

export default {
  state: queryState.procons || defaultState,
  reducers: {
    add (state, { value='' } = {}) {
      if (!state[state.length - 1].value) return state
      return [
        ...state, {
          value,
          id: uid(),
          weight: 2
        }
      ]
    },
    edit (state, edit) {
      const i = findIndex(c => c.id === edit.id, state)
      return adjust(c => ({
        ...c,
        ...edit
      }), i, state)
    },
    remove (state, id) {
      if (state.length <= 1) return state
      const i = findIndex(c => c.id === id, state)
      return remove(i, 1, state)
    }
  }
}
