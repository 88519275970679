import lensPath from 'ramda/src/lensPath'
import set from 'ramda/src/set'
import queryState from 'lib/queryState'

const defaultState = {}

// const defaultState = {
//   a: {
//     a: 2, b: 2, c: 0
//   },
//   b: {
//     a: 0, b: 2, c: 0
//   },
//   c: {
//     a: 1, b: 0, c: 2
//   }
// }

export default {
  state: queryState.ratings || defaultState,
  reducers: {
    setState: (state, payload) => ({ ...state, ...payload }),
    replaceState: (_, payload) => payload,
    edit (state, { optionId, proconId, rating=0 }) {
      if (!optionId || !proconId) throw new Error('Missing parameters')
      var lens = lensPath([optionId, proconId])
      return set(lens, rating, state)
    }
  },
  effects: {
    clean (_, { procons, options, ratings }) {
      const proconsIds = procons.map(procon => procon.id)
      const optionsIds = options.map(option => option.id)
      const newState = optionsIds.reduce((acc, oId) => {
        acc[oId] = proconsIds.reduce((acc2, pId) => {
          const val = (ratings[oId] || {})[pId]
          acc2[pId] = val !== undefined ? val : 0
          return acc2
        }, {})
        return acc
      }, {})
      this.replaceState(newState)
    }
  }
}
