import React, { Component, Fragment } from 'react'
import system from 'system-components/emotion'
import styled from 'react-emotion'
import { Rating } from 'components'

const ItemValue = styled(system({
  is: 'input',
  type: 'text',
  width: '100%',
  bg: 'transparent',
  color: 'primary',
  border: 'none',
  py: 4,
  pl: [3, 4],
  pr: [3, 4]
}))`
  ::placeholder {
    color: silver;
  }
`

export class ProConItem extends Component {
  constructor (props) {
    super(props)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleRatingChange = this.handleRatingChange.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.ref = React.createRef()
  }
  handleKeyDown (e) {
    const { add, item, remove } = this.props
    const keys = {
      Enter: () => item.value.trim() && add(e),
      Backspace: () => {
        if (!item.value.trim()) {
          e.preventDefault()
          remove(item.id)
        }
      }
    }
    keys[e.key] && keys[e.key]()
  }
  handleRatingChange (weight) {
    const { edit, item } = this.props
    edit({ id: item.id, weight })
  }
  handleFocus () {
    this.ref.current.focus()
    this.keepFocus = true
  }
  handleBlur () {
    const { onBlur, item } = this.props
    if (this.keepFocus) {
      this.ref.current.focus()
      this.keepFocus = false
      return
    }
    !item.value.trim() && onBlur(item.id)
  }
  render () {
    const { item, edit, inputRef } = this.props
    if (inputRef) inputRef(this.ref)
    return <Fragment>
      <ItemValue pr={0} css="flex: 1; outline: none;"
        placeholder="add a pro / con..."
        value={item.value} innerRef={this.ref}
        onKeyDown={this.handleKeyDown}
        onBlur={this.handleBlur}
        onChange={e => edit({ id: item.id, value: e.target.value })} />
      <Rating rating={item.weight} css="display: flex; align-items: center; font-size: 24px;"
        pr={[3, 4]} pl={2} onMouseDown={this.handleFocus} onClick={this.handleRatingChange} />
    </Fragment>
  }
}

// Needs to be a Component Class so I can add Refs to it.
export class OptionItem extends Component {
  constructor (props) {
    super(props)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.ref = React.createRef()
  }
  handleKeyDown (e) {
    const { add, remove, item } = this.props
    const keys = {
      Enter: () => item.value.trim() && add(e),
      Backspace: () => {
        if (!item.value.trim()) {
          e.preventDefault()
          remove(item.id)
        }
      }
    }
    keys[e.key] && keys[e.key]()
  }
  handleBlur () {
    const { onBlur, item } = this.props
    !item.value.trim() && onBlur(item.id)
  }
  render () {
    const { item, edit, inputRef } = this.props
    if (inputRef) inputRef(this.ref)
    return <ItemValue css="outline: none;"
      placeholder="add a choice..."
      value={item.value} innerRef={this.ref}
      onKeyDown={this.handleKeyDown}
      onBlur={this.handleBlur}
      onChange={e => edit({ id: item.id, value: e.target.value })} />
  }
}
