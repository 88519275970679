import { default as uid } from 'cuid'
import adjust from 'ramda/src/adjust'
import findIndex from 'ramda/src/findIndex'
import remove from 'ramda/src/remove'
import queryState from 'lib/queryState'

const defaultState = [{
  value: '',
  id: 'a'
}]

// const defaultState = [{
//   value: 'Build a boat',
//   id: 'a'
// }, {
//   value: 'Buy a boat',
//   id: 'b'
// }, {
//   value: 'Pay kids tuition',
//   id: 'c'
// }]

export default {
  state: queryState.options || defaultState,
  reducers: {
    add (state, { value='' } = {}) {
      if (!state[state.length - 1].value) return state
      return [
        ...state, {
          value,
          id: uid()
        }
      ]
    },
    edit (state, edit) {
      const i = findIndex(c => c.id === edit.id, state)
      return adjust(c => ({
        ...c,
        ...edit
      }), i, state)
    },
    remove (state, id) {
      if (state.length <= 1) return state
      const i = findIndex(c => c.id === id, state)
      return remove(i, 1, state)
    }
  }
}
