import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Text, Box, Icon, CopyText, Header, Share } from 'components'
import { stringify as stringifyQuery } from 'lib/queryState'
import { parseUrl } from 'query-string'

const weightSteps = [-2, -1, 1, 2]
const ratingSteps = [0, 1, 2]

const getRating = (oId, pId, ratings) => ratingSteps[(ratings[oId] || {})[pId] || 0]
const makeShareUrl = (options, procons, ratings) => {
  const { url } = parseUrl(location.href)
  const queryString = stringifyQuery(options, procons, ratings)
  return `${url}?${queryString}`
}

const Score = ({ options, procons, ratings, stepIndex }) => {
  if (stepIndex !== 4) return null
  const optionScores = options.reduce((acc, option) => {
    const scores = procons.reduce((acc2, procon) => {
      const weight = weightSteps[procon.weight]
      const rating = getRating(option.id, procon.id, ratings)
      return acc2.concat(weight * rating)
    }, [])
    return acc.concat({
      option,
      total: scores.reduce((a, b) => a + b),
      scores
    })
  }, [])
  const topScoreValue = Math.max(...optionScores.map(optionScore => optionScore.total))
  const winners = optionScores.filter(optionScore => optionScore.total === topScoreValue)
  const isMultipleWinners = winners.length >= 2
  const shareUrl = makeShareUrl(options, procons, ratings)
  return <div>
    {isMultipleWinners &&
      <Text is="h2" textAlign="center">It's a {winners.length} way tie!!!</Text>}
    {winners.map((winner, i) => (
      <Text is="div" pt={[3, 5]} textAlign="center" key={winner.option.id}>
        {isMultipleWinners && <Text is="h3" color="whites.9">Winner {i + 1}</Text>}
        <Header is="h1" mb={[0, 1]}>{winner.option.value}</Header>
        <Text is="div" color="whites.9">with a <Text is="span" color="white">net value score</Text> of</Text>
        <Text is="div" lineHeight={['title', 'copy']} fontSize={7}>{winner.total}</Text>
      </Text>
    ))}
    {shareUrl.length <= 2000 && // url length limit
      <Box mt={[3, 5]}>
        <CopyText width="100%" text={shareUrl} buttonText="Copy URL" />
        <Share url={shareUrl} text="Made the choice easy with decisionize.com! Want to see how?" hashtags={['simple', 'decisions']} />
      </Box>}
    <Box mt={5}>
      <Text is="h2" mb={[2, 3]}>Scoring Details</Text>
      {optionScores.map(optionScore => (
        <div key={optionScore.option.id}>
          <Header is="h3" mb={2}>
            {optionScore.option.value}
            {winners.includes(optionScore) &&
              <Icon name="star" mb={1} css="vertical-align: middle;" ml={2} />}
          </Header>
          <Box is="table" width="100%" mb={[2, 3]}>
            <Text is="tbody" textAlign="center">
              <tr>
                <Text is="th" textAlign="left">Pro / Con</Text>
                <th>Pro/Con</th>
                <th> </th>
                <th>Affect</th>
                <th> </th>
                <th>Score</th>
              </tr>
              {optionScore.scores.map((score, i) => {
                const procon = procons[i]
                return <Text is="tr" mt={2} key={procon.id} >
                  <Text is="td" textAlign="left">{procon.value}</Text>
                  <td>{weightSteps[procon.weight]}</td>
                  <Text is="td" color="whites.6">*</Text>
                  <td>{ratingSteps[getRating(optionScore.option.id, procon.id, ratings)]}</td>
                  <Text is="td" color="whites.6">=</Text>
                  <td>{score}</td>
                </Text>
              })}
              <Text is="tr" mt={3}>
                <td></td>
                <td></td>
                <td></td>
                <Text is="td" fontWeight={8}>Total</Text>
                <Text is="td" color="whites.6">=</Text>
                <td>{optionScore.total}</td>
              </Text>
            </Text>
          </Box>
        </div>
      ))}
    </Box>
  </div>
}

export default connect(
  state => ({
    options: state.options,
    procons: state.procons,
    ratings: state.ratings,
    stepIndex: state.ui.stepIndex
  }),
  dispatch => ({})
)(Score)
