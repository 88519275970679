const breakpoints = ['32em', '48em', '64em']

const space = [0, 4, 8, 16, 32, 64, 128, 256, 512]

const fontSizes = [12, 14, 16, 20, 24, 36, 48, 80, 96]

const fontWeights = [100, 200, 300, 400, 500, 600, 700, 800, 900]

const lineHeights = {
  solid: 1,
  title: 1.25,
  copy: 1.5
}

const letterSpacings = {
  normal: 'normal',
  tracked: '0.1em',
  tight: '-0.05em',
  mega: '0.25em'
}

const fonts = {
  serif: 'athelas, georgia, times, serif',
  sans: "'Source Sans Pro', sans-serif;"
}

const borders = [
  0,
  '1px solid',
  '2px solid',
  '4px solid',
  '8px solid',
  '16px solid',
  '32px solid'
]

const radii = [0, 2, 4, 16, 9999, '100%']

const width = [16, 32, 64, 128, 256]

const heights = [16, 32, 64, 128, 256]

const maxWidths = [16, 32, 64, 128, 256, 512, 768, 1024, 1536]

const colors = {
  red: '#ef5353',
  'light-red': 'rgb(255, 109, 109)',
  'light-green': 'rgb(44, 210, 69)',
  green: 'rgb(11, 173, 32)',
  black: '#000',
  'near-black': '#111',
  'dark-gray': '#333',
  'mid-gray': '#555',
  gray: '#777',
  silver: '#999',
  'light-silver': '#aaa',
  'moon-gray': '#ccc',
  'light-gray': '#eee',
  'near-white': '#f4f4f4',
  white: '#fff',
  transparent: 'transparent',
  primary: '#6c55b1',
  blacks: [
    'rgba(0,0,0,.0125)',
    'rgba(0,0,0,.025)',
    'rgba(0,0,0,.05)',
    'rgba(0,0,0,.1)',
    'rgba(0,0,0,.2)',
    'rgba(0,0,0,.3)',
    'rgba(0,0,0,.4)',
    'rgba(0,0,0,.5)',
    'rgba(0,0,0,.6)',
    'rgba(0,0,0,.7)',
    'rgba(0,0,0,.8)',
    'rgba(0,0,0,.9)'
  ],
  whites: [
    'rgba(255,255,255,.0125)',
    'rgba(255,255,255,.025)',
    'rgba(255,255,255,.05)',
    'rgba(255,255,255,.1)',
    'rgba(255,255,255,.2)',
    'rgba(255,255,255,.3)',
    'rgba(255,255,255,.4)',
    'rgba(255,255,255,.5)',
    'rgba(255,255,255,.6)',
    'rgba(255,255,255,.7)',
    'rgba(255,255,255,.8)',
    'rgba(255,255,255,.9)'
  ],
  brand: {
    twitter: '#07c',
    facebook: '#058'
  }
}

export default {
  breakpoints,
  colors,
  space,
  fontSizes,
  lineHeights,
  fontWeights,
  letterSpacings,
  radii,
  borders,
  maxWidths,
  width,
  heights,
  fonts
}
