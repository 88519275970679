import { default as uid } from 'cuid'
import { parse as parseQuery, stringify as stringifyQuery } from 'query-string'

const DELIMETER = ';;'

export const parse = queryString => {
  const query = parseQuery(queryString)
  const delimeter = query.d || DELIMETER

  const options = (query.o || '')
    .split(delimeter)
    .filter(val => !!val)
    .map(value => ({
      value,
      id: uid()
    }))

  const weights = (query.w || '')
    .split(delimeter)
    .filter(val => !!val)
    .map(str => parseInt(str))

  const procons = (query.p || '')
    .split(delimeter)
    .filter(val => !!val)
    .map((value, i) => ({
      value,
      id: uid(),
      weight: weights[i]
    }))

  const scores = (query.r || '')
    .split(delimeter)
    .filter(val => !!val)
    .map(str => parseInt(str))

  let i = 0
  const ratings = options.reduce((acc, option) => {
    acc[option.id] = procons.reduce((acc2, procon) => {
      acc2[procon.id] = scores[i]
      i++
      return acc2
    }, {})
    return acc
  }, {})

  return {
    options: options.length ? options : undefined,
    procons: procons.length ? procons : undefined,
    ratings: Object.keys(ratings).length ? ratings : undefined
  }
}

export const stringify = (options, procons, ratings) => {
  return stringifyQuery({
    d: DELIMETER,
    v: 0,
    o: options.map(o => o.value).join(DELIMETER),
    p: procons.map(p => p.value).join(DELIMETER),
    w: procons.map(p => p.weight).join(DELIMETER),
    r: options.map(o => procons.map(p => ratings[o.id][p.id]))
      .reduce((a, b) => a.concat(b))
      .join(DELIMETER)
  })
}

export default (location && location.search)
  ? parse(location.search)
  : {}
