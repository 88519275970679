import React from 'react'
import geomIcons from 'geomicons-open/src'
import system from 'system-components/emotion'
import customPaths from './paths'

const paths = Object.assign({}, geomIcons, customPaths)

const Svg = system(
  {
    is: 'svg'
  },
  'space',
  'color'
)

Svg.displayName = 'Svg'

const Icon = ({
  name = 'warning',
  size = '1em',
  fill = 'currentColor',
  viewBox = '0 0 32 32',
  width,
  height,
  ...rest
}) => {
  const path = paths[name]
  return (
    <Svg {...rest}
      width={width || size}
      height={height || size}
      fill={fill}
      data-id={`icon-${name}`}
      viewBox={viewBox}>
      <path d={path} />
    </Svg>
  )
}

export default Icon
