import React from 'react'
import { Text, Flex, Box, Button, Icon } from 'components'

const Share = ({ text='', hashtags=[], url }) => {
  const encodedUrl = encodeURIComponent(url)
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`
  const twitterUrl = `https://twitter.com/intent/tweet?hashtags=${hashtags.join(', ')}&original_referer=${location.href}&text=${text}&url=${encodedUrl}`

  return (
    <Text is={Flex} mt={3} textAlign="center">
      <Button is={'a'} width={1/2} href={twitterUrl} target="_blank" mr={2} bg="blacks.4">
        <Icon mr={2} name="twitter" mb={1} css="vertical-align: middle;"/>
        Tweet
      </Button>
      <Button is={'a'} width={1/2} href={facebookUrl} target="_blank" ml={2} bg="blacks.4">
        <Icon mr={2} name="facebook" mb={1} css="vertical-align: middle;"/>
        Post
      </Button>
    </Text>
  )
}

export default Share
