import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'emotion-theming'
import store from './store'
import theme from './theme'
import App from './App'

import 'normalize.css'
import './style.css'

const AppContainer = () => {
  return <Provider store={store}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </Provider>
}

render(<AppContainer />, document.getElementById('root'))
