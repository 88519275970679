import system from 'system-components/emotion'

const Header = system(
  {
    is: 'h1',
    lineHeight: 'title'
  },
  'space',
  'color',
  'letterSpacing',
  'fontSize'
)

export default Header
