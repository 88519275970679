import system from 'system-components/emotion'
import styled from 'react-emotion'

const Button = styled(system({
  is: 'button',
  border: 'none',
  px: 4,
  py: 3,
  borderRadius: 2,
  color: 'white',
  bg: 'transparent'
}, 'width'))`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  outline: none;
  user-select: none;
`

export default Button
