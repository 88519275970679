import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { ItemWrap, Text, Button, Icon, Box, Link } from 'components'
import system from 'system-components/emotion'
import styled from 'react-emotion'

const RatingButton = styled(system({
  is: Button,
  bg: 'primary',
  px: 3,
  py: 2,
  mx: 1,
  mt: 2
}))`
  opacity: ${props => !!props.active ? 1 : 0.3};
`
const ResultsButton = ({ children, enabled, ...rest }) => {
  return <Button {...rest} disabled={!enabled}
    bg={enabled ? 'primary' : 'blacks.2'}
    color={enabled ? 'white' : 'blacks.7'}>
    {children}
  </Button>
}

class RatingList extends Component {
  constructor (props) {
    super(props)
    this.handleSeeResults = this.handleSeeResults.bind(this)
  }
  shouldComponentUpdate (nextProps) {
    return nextProps.stepIndex === 3
  }
  setRating (optionId, proconId, rating) {
    const { edit, nextSubIndexes } = this.props
    edit({ optionId, proconId, rating })
    // setTimeout(() => nextSubIndexes(1), 300)
  }
  handleSeeResults (e) {
    const { nextStep } = this.props
    if (e.stopPropagation) e.stopPropagation()
    if (e.preventDefault) e.preventDefault()
    nextStep(1)
    return false
  }
  render () {
    const {
      options,
      procons,
      ratings,
      optionIndex,
      proconIndex,
      nextSubIndexes,
      lastSubIndexes
    } = this.props
    const option = options[optionIndex]
    const procon = procons[proconIndex]
    if (!option || ! procon) return <Box p={3}>
      <Text textAlign="center">
        Add Pro/Cons and Options before rating.
      </Text>
    </Box>
    const rating = ratings[option.id] && ratings[option.id][procon.id]
    const currentSubStep = optionIndex * procons.length + proconIndex + 1
    const totalSubSteps = options.length * procons.length
    return <Fragment>
      <ItemWrap py={[2, 4]}>
        <Button px={3} onClick={() => nextSubIndexes(-1)} color={currentSubStep !== 1 ? 'primary' : 'blacks.3'}>
          <Icon name="chevronLeft" />
        </Button>
        <Text is="div" color="blacks.8" textAlign="center" my={0} fontWeight={6} css="flex: auto;">
          <Text my={[2, 3]} color="blacks.6" fontSize={3}>{currentSubStep} / {totalSubSteps}</Text>
          <div>
            <Text is="span" mr={2} color="blacks.6">Does</Text>
            {option.value || 'No value for option'}
          </div>
          <Box mt={[1, 2]}>
            <Text is="span" mr={2} color="blacks.6">result in</Text>
            {procon.value || 'No value for pro / con'}
            <Text is="span" ml={2} color="blacks.6">?</Text>
          </Box>
          <Box my={[2, 3]}>
            <RatingButton onClick={i => this.setRating(option.id, procon.id, 0)} active={rating === 0 ? 'true' : undefined}>
              no
            </RatingButton>
            <RatingButton onClick={i => this.setRating(option.id, procon.id, 1)} active={rating === 1 ? 'true' : undefined}>
              some
            </RatingButton>
            <RatingButton onClick={i => this.setRating(option.id, procon.id, 2)} active={rating === 2 ? 'true' : undefined}>
              yes
            </RatingButton>
          </Box>
        </Text>
        <Button px={3} onClick={() => nextSubIndexes(1)} color={currentSubStep !== options.length * procons.length ? 'primary' : 'blacks.3'}>
          <Icon name="chevronRight" />
        </Button>
      </ItemWrap>
      <Box p={3}>
        <ResultsButton width="100%" enabled={currentSubStep === totalSubSteps}
          onMouseDown={this.handleSeeResults}>
          See Results! <Icon mb={1} name="triangleRight" css="vertical-align: middle;" />
        </ResultsButton>
        {currentSubStep !== totalSubSteps &&
          <Text color="blacks.6" textAlign="center">
            Review all combinations before continuing<br />
            ...or <Link href="javascript:void(0)" color="blacks.6" onClick={lastSubIndexes}>Jump to End</Link> to skip making changes.
          </Text>}
      </Box>
    </Fragment>
  }
}

export default connect(
  state => ({
    options: state.options,
    procons: state.procons,
    ratings: state.ratings,
    optionIndex: state.ui.optionIndex,
    proconIndex: state.ui.proconIndex,
    stepIndex: state.ui.stepIndex
  }),
  dispatch => ({
    edit: dispatch.ratings.edit,
    nextSubIndexes: dispatch.ui.nextSubIndexes,
    lastSubIndexes: dispatch.ui.lastSubIndexes,
    nextStep: dispatch.ui.nextStep
  })
)(RatingList)
