import React from 'react'
import system from 'system-components/emotion'
import styled from 'react-emotion'
import {
  Carousel,
  Overlay,
  OptionItemList,
  ProConItemList,
  RatingList,
  Header,
  Icon,
  Box,
  Text,
  Score,
  Link
} from 'components'

const SHARE_URL = '/?d=%3B%3B&o=Build%20a%20boat%3B%3BBuy%20a%20boat%3B%3BPay%20kids%20tuition&p=Feeling%20of%20accomplishment.%3B%3BSweet%20sweet%20speed%21%3B%3BDebt%20free%20children.&r=2%3B%3B2%3B%3B0%3B%3B0%3B%3B2%3B%3B0%3B%3B1%3B%3B0%3B%3B2&v=0&w=2%3B%3B2%3B%3B3'

const AppContainer = styled('div')`
  height: 100%;
  letter-spacing: 0.01em;
  *::selection {
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.white};
  }
`

const CardContainer = styled(system({
  mx: 4
}))`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const CardBox = styled(system({
  bg: 'whites.11',
  color: 'primary',
  borderRadius: 2
}))`
  overflow-x: hidden;
  overflow-y: scroll;
  box-shadow: 0px 3px 10px -3px rgba(0,0,0,0.75);
  flex-grow: 1;
  user-select: contain;
`

const Card = ({ children, card }) => {
  return <CardContainer>
    {card.title && <Header is="h3" mb={0}>
      {`${card.step}. ${card.title} `}
      <Icon name={card.icon} ml={2} css="vertical-align: middle;" mb={1} />
      </Header>
    }
    {card.description && <Text>
      <Icon name="info" mr={2} mb={1} color="whites.8" css="vertical-align: middle;" />
      {card.description}
      </Text>
    }
    <CardBox>{children}</CardBox>
  </CardContainer>
}

const Panel = ({ children }) => {
  return <Box px={4} css="overflow-y: scroll; height: 100%;">
    {children}
  </Box>
}

const Wrap = system({
  maxWidth: 5,
  mx: 'auto',
  py: [2, 3],
  height: '100%'
})

const Container = styled(system({
  bg: 'primary',
  color: 'white',
  height: '100%',
  fontFamily: 'sans'
}))`
  overflow: hidden;
`

const steps = [{
  step: 1,
  icon: 'list',
  title: 'Choices',
  description: 'What are you deciding between? Add 2 or more choices below, then click next to proceed.'
}, {
  step: 2,
  icon: 'check',
  title: 'Pros & Cons',
  description: 'What pros/cons relate to your decision? Add below and rate each by how it affects your well being.'
}, {
  step: 3,
  icon: 'star',
  title: 'Affect',
  description: 'Finally, what affect does each combination have? Review each, then see your results!'
}]

const App = () => {
  return <AppContainer>
    <Overlay />
    <Container>
      <Wrap>
        <Carousel viewCount={1} >
          <Panel>
            <Header is="h1" letterSpacing="tracked">Decisi<Text is="span" fontSize={4}>🧐</Text>n·ize</Header>
            <Header is="h2" fontSize={3}>
              simplify decision making <Text is="span" fontWeight={2}>in</Text> 3 easy steps
            </Header>
            <Text is="ol" pl={0} css="list-style: none;" mt={3} mb={2}>
              <li>
                <Text is="span" fontSize={3} mt={3}>1. </Text>
                <Text is="span" fontWeight={6} mr={2}>Choices</Text>
                <span>- What you're deciding between</span>
              </li>
              <li>
                <Text is="span" fontSize={3} mt={3}>2. </Text>
                <Text is="span" fontWeight={6} mr={2}>Pros & Cons</Text>
                <span>- What you stand to gain/lose and by how much</span>
              </li>
              <li>
                <Text is="span" fontSize={3} mt={3}>3. </Text>
                <Text is="span" fontWeight={6} mr={2}>Affect</Text>
                <span>- What affect your <i>choices</i> have on your <i>pros & cons</i></span>
              </li>
            </Text>
            <Header is="h3" mt={4} mb={2}>The Goal</Header>
            <Text>To discover which choice returns the highest net value.</Text>
            <Header is="h3" mt={4} mb={2}>Background</Header>
            <Text>The idea for this originated when I made the odd-ball choice to <Link href="https://willhoag.com/writing/post/calculating-your-way-to-career-change" target="_blank">change careers from animator to software developer</Link>. Facing many options, I needed a way to elicit the total value I would receive from each. After some thought, I ended up making a spreadsheet with the steps above and used the numbers to tally the scores.</Text>
            <Text>The idea was simple, but I hadn't heard anybody describe their own decision making process in these terms before. Surely, people must make decisions like this intuitively, but when I asked around, many hadn't considered how they make decisions at all.</Text>
            <Text>Since it worked so well for me, I made this to teach the concepts and be an easy way to <Link href={SHARE_URL}>share results</Link>.</Text>
          </Panel>
          <Card card={steps[0]}>
            <OptionItemList />
          </Card>
          <Card card={steps[1]}>
            <ProConItemList />
          </Card>
          <Card card={steps[2]}>
            <RatingList />
          </Card>
          <Panel>
            <Header is="h3">
              And the winner is...
            </Header>
            <Score />
          </Panel>
          <div>{/* disables double-click selection for last panel/card */}</div>
        </Carousel>
      </Wrap>
    </Container>
  </AppContainer>
}

export default App
